import React from 'react'
import { DESCRIPTIONS } from '../../helpers/constants'
import Layout from '../../components/nigeria/layout'
import { Link, Trans } from "gatsby-plugin-react-i18next";
import SpecialBg from "../../images/nigeria/special-bg.png";
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const WhistleblowerPolicy = () => {
  return (
    <Layout pageTitle="Whistleblower Policy | Moniepoint MFB"
      description={DESCRIPTIONS.WHISTLEBLOWER}>
      <Helmet>
        <link rel="alternate" href="https://moniepoint.com/ng/whistleblower-policy" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng/whistleblower-policy" hreflang="en-ng" />
      </Helmet>
      <GetStartedContainer>
        <div className="bg-mask"></div>
        <div className="content">
          <div className="get-started-section">
            <h2 className={`title`}>
              <Trans>Whistleblower Policy</Trans>
            </h2>
          </div>
        </div>
      </GetStartedContainer>
      <ContentContainer>
        <div className="content">
          <div className="wb-list">
            <div className="wb-list-outer">
              <h1 className="wb-list-header">What is Whistleblowing?</h1>
              <p className="wb-list-copy top">
                The term "whistleblowing" can be defined as raising a concern
                about wrongdoing within an organization. The concern must be
                genuine concern about a crime, criminal offence, miscarriage
                of justice,dangers to health and safety of the environment
                etc.
              </p>
            </div>
            <div className="wb-list-outer">
              <h1 className="wb-list-header">Whistleblowing Channels</h1>
              <p className="wb-list-copy top">
                An effective whistleblowing programme has been set up by Moniepoint MFB. An effective whistleblowing programme is the ability of customers, suppliers, vendors, employees and other stakeholders to report misconduct discreetly and confidentially if they so desire.
              </p>
            </div>
            <div className="wb-list-outer">
              <h1 className="wb-list-header">How do I report?</h1>
              <p className="wb-list-copy top">
                Existing report channel <br></br>
                Internal Email:{" "}
                <a href="mailto:report@moniepoint.com">report@moniepoint.com</a>
                <br></br>
                Telephone: <b>09088 380 339</b>
              </p>
            </div>

            {/* <div className="wb-list-outer">
              <h1 className="wb-list-header">Please let us know</h1>
              <p className="wb-list-copy top">
                Your confidential wishes shall be fully respected
              </p>
            </div>

            <div>
              <div>
                <section>
                  <form>
                    <div className="whistleblower-form">
                      <div>
                        <label htmlFor="officers">
                          Name of Officer(s) involved:
                        </label>
                        <br />
                        <input id="officers" type="text" />
                      </div>
                      <div>
                        <label htmlFor="conduct">Nature of Conduct:</label>
                        <br />
                        <select id="conduct">
                          <option value="Select an option">
                            Select an option
                          </option>
                          <option value="Violence">Violence</option>
                          <option value="Violence">Theft</option>
                          <option value="Violence">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="whistleblower-form-comments">
                      <label htmlFor="comment">Comments:</label>
                      <br />
                      <input id="comment" type="text" />
                    </div>
                    <div className="whistleblower-form-radiobutton">
                      <label htmlFor="#">
                        Would you like to be contacted?
                      </label>
                      <br />
                      <div className="whistleblower-form-radiobutton-content">
                        <div>
                          <input
                            id="yes"
                            type="radio"
                            name="answer"
                            value="Yes"
                          />
                          <label htmlFor="yes">Yes</label>
                        </div>
                        <div>
                          <input
                            id="no"
                            type="radio"
                            name="answer"
                            value="No"
                          />
                          <label htmlFor="no">No</label>
                        </div>
                      </div>
                    </div>
                    <div className="whistleblower-form-info">
                      <div>
                        {" "}
                        <label htmlFor="name">Your Name</label>
                        <br />
                        <input
                          id="name"
                          type="text"
                          name="Your Name"
                          value=""
                        />
                      </div>
                      <div>
                        {" "}
                        <label htmlFor="email">Email</label>
                        <br />
                        <input id="name" type="email" name="Email" value="" />
                      </div>
                      <div>
                        <label htmlFor="phone_number">Phone number</label>
                        <br />
                        <input
                          id="phone_number"
                          type="tel"
                          name="Phone number"
                          value=""
                        />
                      </div>
                    </div>
                    <div className="whistleblower-form-button">
                      <button>Send</button>
                    </div>
                  </form>
                </section>
              </div>
            </div> */}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  )
}

export default WhistleblowerPolicy


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
  background: rgba(3, 87, 238, 1);
  background-size: fill;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  color: white;
  position: relative;

  .bg-mask {
    background: url(${SpecialBg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    opacity: 0.4;
  }
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }

    .get-started-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 58px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 657px;
        margin-bottom: 8px;
      }

      .description {
        max-width: 623px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      .get-started-button {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        margin: 0 auto;
        padding: 16px 24px;
        border: none;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .content {
      padding: 81px 30px 98px;
      .get-started-section {
        .title {
          font-size: 32px;
          line-height: 35.04px;
          margin-bottom: 16px;
        }

        .description {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
`;

const ContentContainer = styled.section`
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }



  .wb-list {
    padding-left: 0;

    &.inner {
      margin-top: 8px;
      padding-left: 30px;
      list-style-position: inside;
    }

    ul {
      list-style-type: disc;
    }

    &.sub {
      list-style-type: circle;
    }
  }

  .wb-list-outer {
    margin-bottom: 40px;
    list-style-position: inside;

    &::marker {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .wb-list-header {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: bolder;
    font-size: 32px;
    line-height: 38px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
    display: inline-block;
    line-height: 1.5;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .wb-list-copy {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    opacity: 0.8;
    margin-bottom: 12px;

    &.top {
      margin-top: 10px;
    }

    b {
        color: rgba(0, 0, 0, 0.9);
    }
  }

  .wb-list-sub {
    padding-left: 0;
    counter-reset: item;
  }

  .wb-list-header {
    font-family: "Founders Grotesk";
    font-weight: 500;
    font-size: 24px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
    display: inline-block;

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  li > b {
    color: rgba(0, 0, 0, 1);
  }

  .wb-list-outer-sub {
    margin-bottom: 16px;

    ul ul {
      li {
        list-style-type: circle;
      }
    }
  }

  ol > li {
    counter-increment: item;
  }

  ol.wb-list-sub > li {
    display: block;
  }

  ol.wb-list-sub > li:before {
    font-size: 24px;
    font-weight: 500;
    content: counters(item, ".") ". ";

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  .wb-table {
    margin: 16px auto;
  border: 1px solid;

  th, td {
    border: 1px solid;
    padding: 6px;
    font-size: 14px;
    }
  }


  .image-container {
    display: flex;
    gap: 16px;

    .wb-image {
      width: 100%;
      height: 100%;
      max-width: 512px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 30px;
    }}
`;